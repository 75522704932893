import { ViewportProvider } from 'context/viewportProvider'
import { Provider } from 'react-redux'
import store from 'redux/store'
import Navigation from 'routes/Navigation'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-tooltip/dist/react-tooltip.css'

function App() {
  return (
    <Provider store={store}>
      <ViewportProvider>
        <Navigation />
      </ViewportProvider>
    </Provider>
  )
}

export default App
