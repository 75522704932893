import { apiSlice } from './states'

import { configureStore } from '@reduxjs/toolkit'

export interface AppStore {
  apiState: any
}

export default configureStore<AppStore>({
  reducer: {
    apiState: apiSlice.reducer,
  },
})
