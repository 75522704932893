import { Languages } from '../../constants'
import SpainFlag from '../Atoms/Flags/SpainFlag'
import Link from '../Atoms/Link/Link'
import { LinkProps } from '../Atoms/Link/useLink'

import GermanFlag from 'components/Atoms/Flags/GermanFlag'
import UKFlag from 'components/Atoms/Flags/UKFlag'

export interface LinkFlagProps extends LinkProps {
  language: Languages
  height?: number
}

const LinkFlag = ({
  disabled,
  className,
  onClick,
  selected,
  language,
  height,
}: LinkFlagProps) => {
  const getFlagComponent = (height: number) => {
    switch (language) {
      case Languages.SPANISH:
        return <SpainFlag height={height} data-testid="spanish-flag" />
      case Languages.ENGLISH:
        return <UKFlag height={height} data-testid="baque-flag" />
      case Languages.GERMAN:
        return <GermanFlag height={height} data-testid="catalan-flag" />
      default:
        return null
    }
  }

  const flagComponent = getFlagComponent(height || 30)

  return (
    <Link
      className={className}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
    >
      {flagComponent}
    </Link>
  )
}

export default LinkFlag
