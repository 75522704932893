import { Icon } from 'models/icons'

const GermanFlag = ({ width, height }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={width}
      height={height || 600}
      viewBox="0 0 5 3"
      xmlSpace="preserve"
      data-testid="flag"
    >
      <path d="M0 0h5v3H0z" />
      <path fill="#D00" d="M0 1h5v2H0z" />
      <path fill="#FFCE00" d="M0 2h5v1H0z" />
    </svg>
  )
}

export default GermanFlag
