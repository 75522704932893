import { Icon } from 'models/icons'

const CiclogreenIcon = ({ width, height }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 2"
      viewBox="0 0 226.06 400"
      width={width || '226.06'}
      height={height || '400'}
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M225.92 184.82C225.54 112.07 125.8 12.64 121.56 8.45l-6.01-5.94-2.53-2.5-2.53 2.5-6.01 5.94C100.2 12.66 0 112.49 0 185.28v.39c.18 52.8 37.46 95.76 83.12 95.76 32.06 0 60.71-20.52 74.75-53.54l2.32-5.46.98-2.3-1.81-1.72-10.15-9.65-2.4-2.28-2.48 2.21-5.88 5.24a38.24 38.24 0 0 1-25.49 9.7c-21.13 0-38.32-17.19-38.32-38.32s17.19-38.32 38.32-38.32c9.39 0 18.42 3.43 25.44 9.66l6.4 5.68 2.69 2.39 2.39-2.69 11.36-12.79 2.39-2.69-2.69-2.39-6.4-5.68a62.555 62.555 0 0 0-41.59-15.8c-34.54 0-62.64 28.1-62.64 62.64s27.62 62.15 61.74 62.63c-8.76 5.98-18.75 9.19-28.95 9.19-32.42 0-58.8-32.22-58.8-71.82v-.24c.17-51.31 64.78-124.96 88.7-150.45 23.93 25.52 88.6 99.28 88.6 150.62v.13l.03.63v.14c.02.24 1.57 25.17-9.13 53.05-14.18 36.95-42.38 62.02-83.81 74.52l-6.09 1.84-2.56.77v2.68l.04 77.41v3.6h20.71l3.6-.01v-3.6l-.03-62.24c55.49-19.66 80.17-58.03 91.11-86.97 11.83-31.28 10.65-58.81 10.44-62.36Z"
        data-name="Capa 1"
        fill="#00c8b4"
      />
    </svg>
  )
}

export default CiclogreenIcon
