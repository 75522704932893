import { useEffect } from 'react'

import { AxiosResponse } from 'axios'

export const useAsync = (
  asyncFn: () => Promise<AxiosResponse<any, any>>,
  successFunction: Function,
  returnFunction: Function,
  errorFunction: Function,
  dependencies: any[] = [],
) => {
  useEffect(() => {
    let isActive = true
    asyncFn()
      .then(result => {
        if (isActive) successFunction(result.data)
      })
      .catch(error => {
        errorFunction(error)
      })

    return () => {
      returnFunction && returnFunction()
      isActive = false
    }
  }, dependencies)
}
