import { routes } from './routes'

import React from 'react'

import Loading from 'pages/Loading'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

export default function Navigation() {
  const { t } = useTranslation('global')
  return (
    <>
      <Routes>
        {routes.map((route: any, idx: number) => {
          return (
            <Route
              key={idx}
              path={route.path}
              element={
                <route.layout>
                  <React.Suspense
                    fallback={<Loading message={t('loading_view')} />}
                  >
                    <route.page />
                  </React.Suspense>
                </route.layout>
              }
            />
          )
        })}
      </Routes>
    </>
  )
}
