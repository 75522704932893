import { createSlice } from '@reduxjs/toolkit'

export const emptyApiState = {
  isCompanySelected1: true,
  isCompanySelected2: true,
}

export const apiSlice = createSlice({
  name: 'api',
  initialState: emptyApiState,
  reducers: {
    createApiState: (_, action) => action.payload,
    modifyApiState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetApiState: () => emptyApiState,
  },
})

export const { createApiState, modifyApiState, resetApiState } =
  apiSlice.actions
export default apiSlice.reducer
