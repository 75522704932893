import { useEffect, useState } from 'react'

const useCountdown = (targetDate: Date) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: any) => {
  const days = countDown ? Math.floor(countDown / (1000 * 60 * 60 * 24)) : 0
  const hours = countDown
    ? Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0')
    : '00'
  const minutes = countDown
    ? Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, '0')
    : '00'
  const seconds = countDown
    ? Math.floor((countDown % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, '0')
    : '00'

  return [days, hours, minutes, seconds]
}

export { useCountdown }
